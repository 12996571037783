import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import { useNavigate, useLocation } from 'react-router-dom';
import {Alert,Container,Row,Col,Form,Button,Table,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen, FaUser,FaEdit} from 'react-icons/fa'
import ChallengeSelectVideo from './ChallengeSelectVideo';
import ReactPlayer from 'react-player'
import moment from 'moment'

export default function UserStats(props) {
    const [form,setForm]=useState(false)
    const [id,setId]=useState(0)
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState({SportName:'',Tags:''})
    const [tags,setTags]=useState([])
    const [show, setShow] = useState(false)
    const [content,setContent]=useState([])
    const [reactions,setReactions]=useState([])
    const [stats,setStats]=useState([{Value:0}])
    const [stat,setStat]=useState({Value:0})
    const points=useRef()
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{

      loadData()

    },[])





    const loadData=()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'admin/sports/'+props.SportID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{


        loadStats(res.data.results[0])

        
      }).catch(err=>{
          setIsLoading(false)
          console.log(err)
      })          
    }

    const handleClose=()=>{
      setShow(false)
    }

    const loadStats=(xstatsList)=>{

      axios.get(process.env.REACT_APP_API_SERVER+'admin/sports/stats/'+props.id,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{

        xstatsList.Tags.split(',').forEach(s=>{
          if (res.data.results.filter(item=>item.StatName==s).length==0){
            res.data.results.push({
              StatName:s,
              Value:null,
              UserUUID:props.id
            })
          }
        })
        
        setStats(res.data.results)
       // console.log(res.data.results)
        setData(xstatsList)
        setTags(xstatsList.Tags.split(','))        
        setIsLoading(false)
        setShow(false)
        
      }).catch(err=>{
          setIsLoading(false)
          console.log(err)
      })          
    }

    const changeValue=(item)=>{
      
      setStat(item)

      setShow(true)
    }




    const tagsList=tags.map((item,index)=>{


        return(
          <tr key={`Tag${index}`}>
            <td width="50"><Button variant="secondery" onClick={()=>changeValue(stats.filter(s=>s.StatName==item)[0])}><FaEdit/></Button></td>
            <td width="300">{item}</td>
            <td>{stats.filter(s=>s.StatName==item)[0].Value}</td>
          </tr>

        )


    })

    const changeStatValue=(e)=>{

      e.preventDefault()
     
      axios.post(process.env.REACT_APP_API_SERVER+'admin/sports/stats',stat,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{

        if (res.data.err==0){
          const xstats=stats
          xstats.forEach(item=>{
            if (item.StatName==stat.StatName){
              item.Value=stat.Value
            }
          })
          loadData()
          
        }else{
          alert('An error has occured')
        }


        
      }).catch(err=>{
          setIsLoading(false)
          console.log(err)
      })  


    }

  return (
    <>
    <Alert variant='secondary'>User Stats || {data.SportName}</Alert>
    {!isLoading &&
    <Table striped>
     <tbody>
    {tagsList}
    </tbody>
    </Table>
    
    }
    {isLoading &&
    <Alert variant='warning'>Loading....Please Wait</Alert>

    }


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{stat.StatName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={changeStatValue}>


          <Form.Group className="mb-3" controlId="formBasicPassword">

            <Form.Control required type="number" placeholder="value" min={0} max={100} value={stat.Value} onChange={(e)=>setStat({...stat,Value:e.target.value})} />
          </Form.Group>
          
          <p align="right">
          <Button variant="primary" type="submit">
            Save
          </Button>
          </p>
        </Form>


        </Modal.Body>

      </Modal>


    </>
  )
}
